.esaContainer {
  padding: 40px;
  width: 70%;
  display: flex;
  flex-direction: column;
  margin: auto;
}

.lst-kix_custom-li-3 > li {
  counter-increment: lst-ctn-kix_custom-li-3;
}

ul.lst-custom-li_1-0 {
  list-style-type: none;
}

ol.lst-custom-li_3-1.start {
  counter-reset: lst-ctn-custom-li_3-1 0;
}

.lst-kix_custom-li-4 > li:before {
  content: "" counter(lst-ctn-kix_custom-li-4, lower-latin) ". ";
}

.lst-kix_custom-li-5 > li:before {
  content: "" counter(lst-ctn-kix_custom-li-5, lower-roman) ". ";
}

.lst-kix_custom-li-6 > li:before {
  content: "" counter(lst-ctn-kix_custom-li-6, decimal) ". ";
}

.lst-kix_custom-li-8 > li:before {
  content: "" counter(lst-ctn-kix_custom-li-8, lower-roman) ". ";
}

ul.lst-custom-li_1-3 {
  list-style-type: none;
}

ul.lst-custom-li_1-4 {
  list-style-type: none;
}

.lst-kix_custom-ol-2-7 > li {
  counter-increment: lst-ctn-kix_custom-ol-2-7;
}

.lst-kix_custom-li-7 > li:before {
  content: "" counter(lst-ctn-kix_custom-li-7, lower-latin) ". ";
}

ul.lst-custom-li_1-1 {
  list-style-type: none;
}

ul.lst-custom-li_1-2 {
  list-style-type: none;
}

ol.lst-kix_custom-li-7.start {
  counter-reset: lst-ctn-kix_custom-li-7 0;
}

ul.lst-custom-li_1-7 {
  list-style-type: none;
}

ul.lst-custom-li_1-8 {
  list-style-type: none;
}

ul.lst-custom-li_1-5 {
  list-style-type: none;
}

ul.lst-custom-li_1-6 {
  list-style-type: none;
}

ol.lst-kix_custom-ol-1.start {
  counter-reset: lst-ctn-kix_custom-ol-1 0;
}

ol.lst-kix_custom-ol-8 {
  list-style-type: none;
}

ol.lst-kix_custom-ol-4 {
  list-style-type: none;
}

ol.lst-kix_custom-ol-5 {
  list-style-type: none;
}

ol.lst-kix_custom-ol-6 {
  list-style-type: none;
}

ol.lst-kix_custom-ol-7 {
  list-style-type: none;
}

ol.lst-kix_custom-ol-2-4 {
  list-style-type: none;
}

ol.lst-kix_custom-ol-2-3 {
  list-style-type: none;
}

ol.lst-kix_custom-ol-2-2 {
  list-style-type: none;
}

.lst-kix_custom-li-1 > li {
  counter-increment: lst-ctn-kix_custom-li-1;
}

ol.lst-kix_custom-ol-2-1 {
  list-style-type: none;
}

ol.lst-kix_custom-ol-2-8 {
  list-style-type: none;
}

ol.lst-kix_custom-ol-2-7 {
  list-style-type: none;
}

ol.lst-custom-li_3-7.start {
  counter-reset: lst-ctn-custom-li_3-7 0;
}

ol.lst-kix_custom-ol-2-6 {
  list-style-type: none;
}

ol.lst-kix_custom-ol-2-5 {
  list-style-type: none;
}

.lst-custom-li_3-2 > li {
  counter-increment: lst-ctn-custom-li_3-2;
}

.lst-kix_custom-ol-1 > li {
  counter-increment: lst-ctn-kix_custom-ol-1;
}

ol.lst-kix_custom-ol-0.start {
  counter-reset: lst-ctn-kix_custom-ol-0 0;
}

.lst-custom-li_5-0 > li:before {
  content: "-  ";
}

.lst-kix_custom-ol-2-5 > li {
  counter-increment: lst-ctn-kix_custom-ol-2-5;
}

.lst-custom-li_5-3 > li:before {
  content: "-  ";
}

.lst-kix_custom-ol-6 > li:before {
  content: "" counter(lst-ctn-kix_custom-ol-6, decimal) ". ";
}

.lst-kix_custom-ol-7 > li:before {
  content: "" counter(lst-ctn-kix_custom-ol-7, lower-latin) ". ";
}

.lst-custom-li_5-2 > li:before {
  content: "-  ";
}

ol.lst-kix_custom-ol-7.start {
  counter-reset: lst-ctn-kix_custom-ol-7 0;
}

.lst-custom-li_5-1 > li:before {
  content: "-  ";
}

.lst-kix_custom-ol-8 > li:before {
  content: "" counter(lst-ctn-kix_custom-ol-8, lower-roman) ". ";
}

ol.lst-kix_custom-ol-0 {
  list-style-type: none;
}

.lst-custom-li_5-7 > li:before {
  content: "-  ";
}

ol.lst-kix_custom-ol-1 {
  list-style-type: none;
}

.lst-kix_custom-ol-2 > li:before {
  content: "" counter(lst-ctn-kix_custom-ol-2, lower-roman) ". ";
}

.lst-kix_custom-ol-3 > li:before {
  content: "" counter(lst-ctn-kix_custom-ol-3, decimal) ". ";
}

ol.lst-kix_custom-ol-2 {
  list-style-type: none;
}

.lst-custom-li_5-6 > li:before {
  content: "-  ";
}

.lst-custom-li_5-8 > li:before {
  content: "-  ";
}

ol.lst-kix_custom-ol-3 {
  list-style-type: none;
}

.lst-kix_custom-ol-1 > li:before {
  content: "" counter(lst-ctn-kix_custom-ol-1, lower-latin) ". ";
}

.lst-kix_custom-ol-5 > li:before {
  content: "" counter(lst-ctn-kix_custom-ol-5, lower-roman) ". ";
}

.lst-custom-li_5-4 > li:before {
  content: "-  ";
}

.lst-kix_custom-li-5 > li {
  counter-increment: lst-ctn-kix_custom-li-5;
}

.lst-custom-li_5-5 > li:before {
  content: "-  ";
}

.lst-kix_custom-ol-4 > li:before {
  content: "" counter(lst-ctn-kix_custom-ol-4, lower-latin) ". ";
}

ol.lst-kix_custom-ol-2-5.start {
  counter-reset: lst-ctn-kix_custom-ol-2-5 0;
}

.lst-kix_custom-ol-6 > li {
  counter-increment: lst-ctn-kix_custom-ol-6;
}

.lst-kix_custom-ol-3 > li {
  counter-increment: lst-ctn-kix_custom-ol-3;
}

ol.lst-kix_custom-ol-6.start {
  counter-reset: lst-ctn-kix_custom-ol-6 0;
}

.lst-custom-li_6-1 > li:before {
  content: "-  ";
}

.lst-custom-li_6-3 > li:before {
  content: "-  ";
}

.lst-custom-li_6-0 > li:before {
  content: "-  ";
}

.lst-custom-li_6-4 > li:before {
  content: "-  ";
}

.lst-custom-li_3-0 > li {
  counter-increment: lst-ctn-custom-li_3-0;
}

.lst-custom-li_3-6 > li {
  counter-increment: lst-ctn-custom-li_3-6;
}

.lst-custom-li_6-2 > li:before {
  content: "-  ";
}

ol.lst-custom-li_3-2.start {
  counter-reset: lst-ctn-custom-li_3-2 0;
}

.lst-custom-li_6-8 > li:before {
  content: "-  ";
}

ol.lst-kix_custom-ol-2-4.start {
  counter-reset: lst-ctn-kix_custom-ol-2-4 0;
}

.lst-custom-li_6-5 > li:before {
  content: "-  ";
}

.lst-custom-li_6-7 > li:before {
  content: "-  ";
}

.lst-kix_custom-ol-2-2 > li {
  counter-increment: lst-ctn-kix_custom-ol-2-2;
}

.lst-custom-li_6-6 > li:before {
  content: "-  ";
}

.lst-custom-li_2-7 > li:before {
  content: "-  ";
}

.lst-custom-li_2-5 > li:before {
  content: "-  ";
}

.lst-kix_custom-ol-2-8 > li:before {
  content: "" counter(lst-ctn-kix_custom-ol-2-8, lower-roman) ". ";
}

.lst-kix_custom-ol-2-6 > li:before {
  content: "" counter(lst-ctn-kix_custom-ol-2-6, decimal) ". ";
}

ol.lst-kix_custom-ol-2.start {
  counter-reset: lst-ctn-kix_custom-ol-2 0;
}

.lst-kix_custom-ol-2-4 > li:before {
  content: "" counter(lst-ctn-kix_custom-ol-2-4, lower-latin) ". ";
}

.lst-kix_custom-li-8 > li {
  counter-increment: lst-ctn-kix_custom-li-8;
}

ol.lst-custom-li_3-0.start {
  counter-reset: lst-ctn-custom-li_3-0 0;
}

ol.lst-kix_custom-ol-5.start {
  counter-reset: lst-ctn-kix_custom-ol-5 0;
}

.lst-kix_custom-ol-2-2 > li:before {
  content: "" counter(lst-ctn-kix_custom-ol-2-2, lower-roman) ". ";
}

ol.lst-kix_custom-li-0.start {
  counter-reset: lst-ctn-kix_custom-li-0 0;
}

.lst-custom-li_4-1 > li:before {
  content: "-  ";
}

.lst-kix_custom-ol-2-1 > li {
  counter-increment: lst-ctn-kix_custom-ol-2-1;
}

.lst-kix_custom-ol-0 > li:before {
  content: "" counter(lst-ctn-kix_custom-ol-0, decimal) ". ";
}

.lst-custom-li_4-3 > li:before {
  content: "-  ";
}

.lst-custom-li_4-5 > li:before {
  content: "-  ";
}

ol.lst-kix_custom-ol-2-6.start {
  counter-reset: lst-ctn-kix_custom-ol-2-6 0;
}

.lst-custom-li_3-5 > li {
  counter-increment: lst-ctn-custom-li_3-5;
}

ol.lst-kix_custom-li-3.start {
  counter-reset: lst-ctn-kix_custom-li-3 0;
}

.lst-custom-li_3-4 > li {
  counter-increment: lst-ctn-custom-li_3-4;
}

.lst-kix_custom-ol-2 > li {
  counter-increment: lst-ctn-kix_custom-ol-2;
}

ol.lst-kix_custom-ol-2-0 {
  list-style-type: none;
}

ol.lst-kix_custom-li-1.start {
  counter-reset: lst-ctn-kix_custom-li-1 0;
}

ol.lst-kix_custom-ol-3.start {
  counter-reset: lst-ctn-kix_custom-ol-3 0;
}

ul.lst-custom-li_2-8 {
  list-style-type: none;
}

.lst-kix_custom-ol-2-0 > li {
  counter-increment: lst-ctn-kix_custom-ol-2-0;
}

ul.lst-custom-li_2-2 {
  list-style-type: none;
}

ul.lst-custom-li_2-3 {
  list-style-type: none;
}

ul.lst-custom-li_2-0 {
  list-style-type: none;
}

ul.lst-custom-li_2-1 {
  list-style-type: none;
}

ul.lst-custom-li_2-6 {
  list-style-type: none;
}

.lst-custom-li_1-1 > li:before {
  content: "-  ";
}

ul.lst-custom-li_2-7 {
  list-style-type: none;
}

ul.lst-custom-li_2-4 {
  list-style-type: none;
}

ul.lst-custom-li_2-5 {
  list-style-type: none;
}

.lst-custom-li_1-3 > li:before {
  content: "-  ";
}

ol.lst-kix_custom-ol-2-8.start {
  counter-reset: lst-ctn-kix_custom-ol-2-8 0;
}

ol.lst-kix_custom-li-2.start {
  counter-reset: lst-ctn-kix_custom-li-2 0;
}

.lst-custom-li_1-7 > li:before {
  content: "-  ";
}

.lst-custom-li_1-5 > li:before {
  content: "-  ";
}

.lst-kix_custom-li-3 > li:before {
  content: "" counter(lst-ctn-kix_custom-li-3, decimal) ". ";
}

.lst-kix_custom-ol-8 > li {
  counter-increment: lst-ctn-kix_custom-ol-8;
}

.lst-custom-li_2-1 > li:before {
  content: "-  ";
}

.lst-custom-li_2-3 > li:before {
  content: "-  ";
}

.lst-kix_custom-li-1 > li:before {
  content: "" counter(lst-ctn-kix_custom-li-1, lower-latin) ". ";
}

ol.lst-custom-li_3-1 {
  list-style-type: none;
}

.lst-kix_custom-ol-2-8 > li {
  counter-increment: lst-ctn-kix_custom-ol-2-8;
}

ol.lst-custom-li_3-2 {
  list-style-type: none;
}

.lst-custom-li_3-1 > li {
  counter-increment: lst-ctn-custom-li_3-1;
}

ol.lst-custom-li_3-3 {
  list-style-type: none;
}

ol.lst-custom-li_3-4.start {
  counter-reset: lst-ctn-custom-li_3-4 0;
}

ol.lst-custom-li_3-4 {
  list-style-type: none;
}

ol.lst-custom-li_3-0 {
  list-style-type: none;
}

ol.lst-kix_custom-ol-2-7.start {
  counter-reset: lst-ctn-kix_custom-ol-2-7 0;
}

.lst-custom-li_3-0 > li:before {
  content: "" counter(lst-ctn-custom-li_3-0, decimal) ". ";
}

ul.lst-custom-li_5-7 {
  list-style-type: none;
}

ul.lst-custom-li_5-8 {
  list-style-type: none;
}

.lst-custom-li_3-1 > li:before {
  content: "" counter(lst-ctn-custom-li_3-1, lower-latin) ". ";
}

.lst-custom-li_3-2 > li:before {
  content: "" counter(lst-ctn-custom-li_3-2, lower-roman) ". ";
}

ul.lst-custom-li_5-5 {
  list-style-type: none;
}

ul.lst-custom-li_5-6 {
  list-style-type: none;
}

.lst-custom-li_3-5 > li:before {
  content: "" counter(lst-ctn-custom-li_3-5, lower-roman) ". ";
}

ul.lst-custom-li_5-0 {
  list-style-type: none;
}

.lst-custom-li_3-4 > li:before {
  content: "" counter(lst-ctn-custom-li_3-4, lower-latin) ". ";
}

ul.lst-custom-li_5-3 {
  list-style-type: none;
}

.lst-custom-li_3-3 > li:before {
  content: "" counter(lst-ctn-custom-li_3-3, decimal) ". ";
}

ol.lst-custom-li_3-5 {
  list-style-type: none;
}

ul.lst-custom-li_5-4 {
  list-style-type: none;
}

ol.lst-custom-li_3-6 {
  list-style-type: none;
}

ul.lst-custom-li_5-1 {
  list-style-type: none;
}

ol.lst-custom-li_3-7 {
  list-style-type: none;
}

ul.lst-custom-li_5-2 {
  list-style-type: none;
}

.lst-kix_custom-li-2 > li {
  counter-increment: lst-ctn-kix_custom-li-2;
}

ol.lst-custom-li_3-8 {
  list-style-type: none;
}

.lst-custom-li_3-8 > li:before {
  content: "" counter(lst-ctn-custom-li_3-8, lower-roman) ". ";
}

.lst-custom-li_3-6 > li:before {
  content: "" counter(lst-ctn-custom-li_3-6, decimal) ". ";
}

.lst-kix_custom-ol-2-6 > li {
  counter-increment: lst-ctn-kix_custom-ol-2-6;
}

.lst-custom-li_3-7 > li:before {
  content: "" counter(lst-ctn-custom-li_3-7, lower-latin) ". ";
}

.lst-kix_custom-li-4 > li {
  counter-increment: lst-ctn-kix_custom-li-4;
}

ol.lst-kix_custom-ol-4.start {
  counter-reset: lst-ctn-kix_custom-ol-4 0;
}

.lst-custom-li_4-8 > li:before {
  content: "-  ";
}

.lst-custom-li_4-7 > li:before {
  content: "-  ";
}

.lst-kix_custom-ol-7 > li {
  counter-increment: lst-ctn-kix_custom-ol-7;
}

ul.lst-custom-li_4-8 {
  list-style-type: none;
}

ul.lst-custom-li_4-6 {
  list-style-type: none;
}

ul.lst-custom-li_4-7 {
  list-style-type: none;
}

ol.lst-kix_custom-li-4.start {
  counter-reset: lst-ctn-kix_custom-li-4 0;
}

ul.lst-custom-li_4-0 {
  list-style-type: none;
}

ul.lst-custom-li_4-1 {
  list-style-type: none;
}

ol.lst-custom-li_3-3.start {
  counter-reset: lst-ctn-custom-li_3-3 0;
}

ul.lst-custom-li_4-4 {
  list-style-type: none;
}

ul.lst-custom-li_4-5 {
  list-style-type: none;
}

ul.lst-custom-li_4-2 {
  list-style-type: none;
}

ul.lst-custom-li_4-3 {
  list-style-type: none;
}

.lst-kix_custom-ol-0 > li {
  counter-increment: lst-ctn-kix_custom-ol-0;
}

.lst-kix_custom-ol-2-0 > li:before {
  content: "" counter(lst-ctn-kix_custom-ol-2-0, decimal) ". ";
}

.lst-custom-li_3-3 > li {
  counter-increment: lst-ctn-custom-li_3-3;
}

ol.lst-kix_custom-li-5.start {
  counter-reset: lst-ctn-kix_custom-li-5 0;
}

.lst-custom-li_2-6 > li:before {
  content: "-  ";
}

.lst-custom-li_3-7 > li {
  counter-increment: lst-ctn-custom-li_3-7;
}

.lst-custom-li_2-4 > li:before {
  content: "-  ";
}

.lst-custom-li_2-8 > li:before {
  content: "-  ";
}

ol.lst-kix_custom-li-6.start {
  counter-reset: lst-ctn-kix_custom-li-6 0;
}

.lst-kix_custom-ol-5 > li {
  counter-increment: lst-ctn-kix_custom-ol-5;
}

.lst-kix_custom-ol-2-7 > li:before {
  content: "" counter(lst-ctn-kix_custom-ol-2-7, lower-latin) ". ";
}

.lst-kix_custom-ol-2-3 > li {
  counter-increment: lst-ctn-kix_custom-ol-2-3;
}

.lst-kix_custom-ol-2-1 > li:before {
  content: "" counter(lst-ctn-kix_custom-ol-2-1, lower-latin) ". ";
}

.lst-kix_custom-ol-2-5 > li:before {
  content: "" counter(lst-ctn-kix_custom-ol-2-5, lower-roman) ". ";
}

ol.lst-kix_custom-ol-8.start {
  counter-reset: lst-ctn-kix_custom-ol-8 0;
}

ol.lst-kix_custom-ol-2-0.start {
  counter-reset: lst-ctn-kix_custom-ol-2-0 0;
}

ol.lst-custom-li_3-8.start {
  counter-reset: lst-ctn-custom-li_3-8 0;
}

.lst-kix_custom-ol-2-3 > li:before {
  content: "" counter(lst-ctn-kix_custom-ol-2-3, decimal) ". ";
}

ol.lst-kix_custom-li-7 {
  list-style-type: none;
}

ol.lst-kix_custom-li-8 {
  list-style-type: none;
}

ol.lst-kix_custom-li-3 {
  list-style-type: none;
}

ol.lst-kix_custom-li-4 {
  list-style-type: none;
}

ol.lst-kix_custom-li-5 {
  list-style-type: none;
}

ol.lst-kix_custom-li-6 {
  list-style-type: none;
}

.lst-custom-li_4-0 > li:before {
  content: "-  ";
}

ol.lst-kix_custom-li-0 {
  list-style-type: none;
}

.lst-custom-li_3-8 > li {
  counter-increment: lst-ctn-custom-li_3-8;
}

ol.lst-kix_custom-li-1 {
  list-style-type: none;
}

ol.lst-kix_custom-li-2 {
  list-style-type: none;
}

ol.lst-kix_custom-ol-2-3.start {
  counter-reset: lst-ctn-kix_custom-ol-2-3 0;
}

.lst-custom-li_4-4 > li:before {
  content: "-  ";
}

.lst-custom-li_4-2 > li:before {
  content: "-  ";
}

.lst-custom-li_4-6 > li:before {
  content: "-  ";
}

.lst-kix_custom-ol-4 > li {
  counter-increment: lst-ctn-kix_custom-ol-4;
}

.lst-kix_custom-li-7 > li {
  counter-increment: lst-ctn-kix_custom-li-7;
}

.lst-kix_custom-li-6 > li {
  counter-increment: lst-ctn-kix_custom-li-6;
}

ol.lst-kix_custom-ol-2-2.start {
  counter-reset: lst-ctn-kix_custom-ol-2-2 0;
}

ol.lst-kix_custom-li-8.start {
  counter-reset: lst-ctn-kix_custom-li-8 0;
}

.lst-kix_custom-li-0 > li {
  counter-increment: lst-ctn-kix_custom-li-0;
}

ol.lst-custom-li_3-6.start {
  counter-reset: lst-ctn-custom-li_3-6 0;
}

ul.lst-custom-li_6-6 {
  list-style-type: none;
}

ul.lst-custom-li_6-7 {
  list-style-type: none;
}

ul.lst-custom-li_6-4 {
  list-style-type: none;
}

ul.lst-custom-li_6-5 {
  list-style-type: none;
}

ul.lst-custom-li_6-8 {
  list-style-type: none;
}

.lst-kix_custom-ol-2-4 > li {
  counter-increment: lst-ctn-kix_custom-ol-2-4;
}

.lst-custom-li_1-0 > li:before {
  content: "-  ";
}

ul.lst-custom-li_6-2 {
  list-style-type: none;
}

ul.lst-custom-li_6-3 {
  list-style-type: none;
}

.lst-custom-li_1-2 > li:before {
  content: "-  ";
}

ul.lst-custom-li_6-0 {
  list-style-type: none;
}

ul.lst-custom-li_6-1 {
  list-style-type: none;
}

.lst-custom-li_1-4 > li:before {
  content: "-  ";
}

ol.lst-custom-li_3-5.start {
  counter-reset: lst-ctn-custom-li_3-5 0;
}

.lst-custom-li_1-6 > li:before {
  content: "-  ";
}

li.li-custom-custom-bullet-0:before {
  margin-left: -18pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 18pt;
}

.lst-kix_custom-li-2 > li:before {
  content: "" counter(lst-ctn-kix_custom-li-2, lower-roman) ". ";
}

.lst-custom-li_2-0 > li:before {
  content: "-  ";
}

.lst-kix_custom-li-0 > li:before {
  content: "" counter(lst-ctn-kix_custom-li-0, decimal) ". ";
}

.lst-custom-li_1-8 > li:before {
  content: "-  ";
}

.lst-custom-li_2-2 > li:before {
  content: "-  ";
}

ol.lst-kix_custom-ol-2-1.start {
  counter-reset: lst-ctn-kix_custom-ol-2-1 0;
}

ol {
  margin: 0;
  padding: 0;
}

table td,
table th {
  padding: 0;
}

.custom-c2 {
  margin-left: 36pt;
  padding-top: 0pt;
  padding-left: 0pt;
  padding-bottom: 0pt;
  line-height: 1.5;
  orphans: 2;
  widows: 2;
  text-align: left;
}

.custom-c22 {
  margin-left: 36pt;
  padding-top: 12pt;
  padding-left: 0pt;
  padding-bottom: 0pt;
  line-height: 1.5;
  orphans: 2;
  widows: 2;
  text-align: left;
}

.custom-c19 {
  margin-left: 36pt;
  padding-top: 0pt;
  padding-left: 0pt;
  padding-bottom: 12pt;
  line-height: 1.5;
  orphans: 2;
  widows: 2;
  text-align: left;
}

.custom-c1 {
  color: #000000;
  font-weight: 600;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 14pt;

  font-style: normal;
}

.custom-c21 {
  background-color: #ffffff;
  padding-top: 12pt;
  padding-bottom: 6pt;
  line-height: 1.5;
  orphans: 2;
  widows: 2;
  text-align: left;
}

.custom-c3 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;

  font-style: normal;
}

.custom-c17 {
  color: #000000;
  font-weight: 600;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 20pt;

  font-style: normal;
}

.custom-c4 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.5;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}

.custom-c12 {
  color: #000000;
  font-weight: 600;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 16pt;

  font-style: normal;
}

.custom-c8 {
  padding-top: 12pt;
  padding-bottom: 12pt;
  line-height: 1.5;
  orphans: 2;
  widows: 2;
  text-align: left;
}

.custom-c5 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.5;
  orphans: 2;
  widows: 2;
  text-align: left;
}

.custom-c10 {
  color: #1d1f25;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  font-style: normal;
}

.custom-c9 {
  color: #000000;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 14pt;
  font-style: normal;
}

.custom-c15 {
  color: #0000ff;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  font-style: normal;
}

.custom-c15 {
  color: #1155cc;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  font-style: normal;
}

.custom-c14 {
  color: #000000;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 10pt;
  font-style: normal;
}

.custom-c11 {
  text-decoration-skip-ink: none;
  -webkit-text-decoration-skip: none;
  color: #1155cc;
  text-decoration: underline;
}

.custom-c23 {
  background-color: #ffffff;
  max-width: 468pt;
  padding: 72pt 72pt 72pt 72pt;
}

.custom-c7 {
  font-weight: 400;
}

.custom-c18 {
  color: inherit;
  text-decoration: inherit;
}

.custom-c13 {
  padding: 0;
  margin: 0;
}

.custom-c6 {
  height: 11pt;
}

.custom-c24 {
}

.custom-c0 {
  font-weight: 700;
}

.custom-c20 {
  color: #1d1f25;
}

.custom-title {
  padding-top: 0pt;
  color: #000000;
  font-weight: 600;
  font-size: 20pt;
  padding-bottom: 0pt;

  line-height: 1.5;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}

.custom-subtitle {
  padding-top: 0pt;
  color: #000000;
  font-weight: 600;
  font-size: 16pt;
  padding-bottom: 0pt;

  line-height: 1.5;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}

li {
  color: #000000;
  font-size: 11pt;
}

p {
  margin: 0;
  color: #000000;
  font-size: 11pt;
}

h1 {
  padding-top: 0pt;
  color: #000000;
  font-weight: 600;
  font-size: 14pt;
  padding-bottom: 0pt;

  line-height: 1.5;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}

h2 {
  padding-top: 18pt;
  color: #000000;
  font-size: 16pt;
  padding-bottom: 6pt;

  line-height: 1.5;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}

h3 {
  padding-top: 16pt;
  color: #434343;
  font-size: 14pt;
  padding-bottom: 4pt;

  line-height: 1.5;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}

h4 {
  padding-top: 14pt;
  color: #666666;
  font-size: 12pt;
  padding-bottom: 4pt;

  line-height: 1.5;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}

h5 {
  padding-top: 12pt;
  color: #666666;
  font-size: 11pt;
  padding-bottom: 4pt;

  line-height: 1.5;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}

h6 {
  padding-top: 12pt;
  color: #666666;
  font-size: 11pt;
  padding-bottom: 4pt;

  line-height: 1.5;
  page-break-after: avoid;
  font-style: italic;
  orphans: 2;
  widows: 2;
  text-align: left;
}

@media (max-width: 1200px) {
  .esaContainer {
    padding: 40px 0;
    width: 90%;
    display: flex;
    flex-direction: column;
    margin: auto;
  }
}
