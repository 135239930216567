.our-vision-container {
  .our-vision-header-subtitle {
    max-width: 742px;
  }
  .our-vision-header-description {
    max-width: 674px;
  }
  .vision-color-block {
    padding-top: 60px;
    padding-bottom: 60px;
    margin-bottom: 214px;
  }

  .vision-works-title {
    margin-bottom: 107px;
  }
  .vision-works-container {
    padding: 0 14vw;
    display: flex;
    justify-content: space-between;
    margin-bottom: 273px;
    @media only screen and (max-width: 600px) {
      flex-direction: column;
      padding: 0 43px;
      margin-bottom: 111px;
    }
    .vision-circles-list-container {
      @media only screen and (max-width: 600px) {
        margin-bottom: 125px;
      }
    }
    .vision-works-image {
      width: 670px;
      height: 1000px;
      margin-left: 55px;
      background-color: #a7a7a7;
      @media only screen and (max-width: 600px) {
        width: auto;
        height: 425px;
        margin-left: 0;
      }
    }
  }
}
