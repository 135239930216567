
.ambassador-container {
  .ambassador-opportunities-descriptions {
    max-width: 635px;
    width: auto;
  }
  .ambassador-opportunities-subtitle {
    max-width: 484px;
    width: auto;
    margin-right: 120px;
  }
  .ambassador-header-subtitle {
    max-width: 1061px;
    padding-bottom: calc(168px - 3vw);
  }
  .ambassador-second-image-block-description {
    max-width: 514px;
  }

  .ambassador-takes-description {
    max-width: 604px;
  }
  .ambassador-takes-title {
    max-width: 651px;
  }
  .ambassador-takes-image {
    max-width: 751px;
    max-height: 635px;
    width: auto;
  }
  .ambassador-steps-image {
    max-width: 735px;
    height: 635px;
    width: auto;
  }

  .ambassador-steps-description {
    max-width: 635px;
  }


}
